import $ from "jquery";
import { URLS } from "../config.js";

class Cart {
  static add(product_id, quantity = 1, options = {}) {
    return Cart.#cartRequestPromise("add", { product_id, quantity, ...options });
  }

  static update(cart_id, quantity = 1) {
    return Cart.#cartRequestPromise("edit", { cart_id, quantity });
  }

  static remove(key) {
    return Cart.#cartRequestPromise("remove", { key });
  }

  static #cartRequestPromise(ctx, data) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: URLS[`cart_${ctx}`],
        type: "post",
        data,
        dataType: "json",
        beforeSend: (jqXHR, settings) => {
          $(document).trigger(`cart.${ctx}.beforeSend`, {
            jqXHR,
            settings,
            resolve,
            reject,
            data,
          });
        },
        complete: (jqXHR, textStatus) => {
          $(document).trigger(`cart.${ctx}.complete`, {
            jqXHR,
            textStatus,
            data,
          });
        },
        success: (response, textStatus, jqXHR) => {
          $(document).trigger(`cart.${ctx}.success`, {
            response,
            textStatus,
            jqXHR,
            data,
            resolve,
            reject,
          });
          resolve();
        },
        error: (jqXHR, textStatus, errorThrown) => {
          $(document).trigger(`cart.${ctx}.error`, {
            jqXHR,
            textStatus,
            errorThrown,
            resolve,
            reject,
            data,
          });
          reject();
        },
      });
    });
  }
}
export { Cart as default };
