import $ from "jquery";
import App from "../../scripts/src/App";

class MiniCartClass {
  #isOpen = false;

  constructor() {
    this.$modal = $(".js-cart-modal");
  }
  show() {
    this.$modal.addClass("cart--open");
    this.#isOpen = true;
    App.scrollBlock("mini-cart");
    App.addUnderlay("mini-cart");
  }

  hide() {
    this.$modal.removeClass("cart--open");
    this.#isOpen = false;
    App.scrollUnblock("mini-cart");
    App.removeUnderlay("mini-cart");
  }

  toggle() {
    if (this.#isOpen) {
      this.hide();
    } else {
      this.show();
    }
  }
}

export default new MiniCartClass();
