export default class Message {
  _element;
  timeout;

  constructor(message, type, dismissible = true, timeout = 0) {
    this.timeout = timeout;
  }

  get el() {
    return this._element;
  }
}
