import $ from "jquery";
import throttle from "lodash/throttle";
import CustomProperty from "./CustomProperty";
import AppMessageCollection from "./Message/MessageCollection";
import BootstrapAlertMessage from "./Message/BootstrapAlertMessage";

/**
 * The main application file. You can import it as many times as you want,
 * and you will get the same instance
 * @class App
 */
class App {
  #customProperties = {};
  /**
   * @private
   * @type {Object}
   */
  #resizeHandlers = {};

  #scrollBlockers = [];
  #underlays = [];

  /** @type {AppMessageCollection} */
  MessageCollection;

  constructor() {
    this.#setCustomProperties();

    this.MessageCollection = new AppMessageCollection(
      BootstrapAlertMessage,
      document.querySelector("#messages"),
    );

    window.addEventListener(
      "resize",
      throttle(() => {
        Object.values(this.#resizeHandlers).map((fn) => fn.call(this));
      }, 250),
    );
  }
  #setCustomProperties() {
    // vh
    this.addCustomProperty("vh", (document.documentElement.clientHeight * 0.01).toFixed(2) + "px");
    this.subscribeResize(
      "vh",
      (() => {
        let prevClientHeight;
        return () => {
          let clientHeight = document.documentElement.clientHeight;
          if (clientHeight === prevClientHeight) return;
          requestAnimationFrame(() => {
            this.#customProperties["vh"].value =
              (document.documentElement.clientHeight * 0.01).toFixed(2) + "px";
            prevClientHeight = clientHeight;
          });
        };
      })(),
    );

    // Scrollbar width
    this.addCustomProperty(
      "sb-width",
      window.innerWidth - document.documentElement.clientWidth + "px",
    );
    this.subscribeResize("sb-width", () => {
      if ($("body").hasClass("no-scroll")) return;
      this.#customProperties["sb-width"].value =
        window.innerWidth - document.documentElement.clientWidth + "px";
    });

    // header-height
    this.addCustomProperty("header-height", $(".site-header").outerHeight() + "px");
    this.subscribeResize("header-height", () => {
      this.#customProperties["header-height"].value = $(".site-header").outerHeight() + "px";
    });
  }

  /**
   * Creates CustomProperty and keeps it in App instance
   * @param {string} name The CSS property name
   * @param {string|number} value The CSS property value
   * @param {HTMLElement} [ctx=document.documentElement] The DOM Element style with apply to
   * @returns this
   * @example
   * import app from 'App';
   * app.addCustomProperty('hello', 'world');
   */
  addCustomProperty(name, value, ctx = document.documentElement) {
    this.#customProperties[name] = new CustomProperty(name, value, ctx);
    return this;
  }

  /**
   * Get CSS property from App instance
   * @param {string} name The CSS property name
   * @returns {any}
   * @example
   * import app from 'App';
   * app.getCustomProperty('hello');
   */
  getCustomProperty(name) {
    return this.#customProperties[name].value;
  }

  /**
   * Add handler to window resize event
   * @param {string} name The handler identifier
   * @param {fn} handler The callback to run on window resize
   * @returns this
   * @example
   * import app from 'App';
   * app.subscribeResize('my-resize-handler', () => {console.log('I'm listening to resize')});
   */
  subscribeResize(name, handler) {
    this.#resizeHandlers[name] = handler;
    return this;
  }
  /**
   * Removes handler from window resize event
   * @param {string} name The handler identifier
   * @returns this
   * @example
   * import app from 'App';
   * app.unsubscribeResize('my-resize-handler');
   */
  unsubscribeResize(name) {
    delete this.#resizeHandlers[name];
    return this;
  }

  scrollBlock(ns) {
    if (!this.#scrollBlockers.includes(ns)) {
      this.#scrollBlockers.push(ns);
      $("body").addClass("no-scroll");
    }
  }

  scrollUnblock(ns) {
    if (this.#scrollBlockers.includes(ns)) {
      this.#scrollBlockers = this.#scrollBlockers.filter((item) => item !== ns);
      if (!this.#scrollBlockers.length) {
        $("body").removeClass("no-scroll");
      }
    }
  }

  addUnderlay(ns) {
    if (!this.#underlays.includes(ns)) {
      this.#underlays.push(ns);
      $("body").addClass("has-underlay");
    }
  }

  removeUnderlay(ns) {
    if (this.#underlays.includes(ns)) {
      this.#underlays = this.#underlays.filter((item) => item !== ns);
      if (!this.#underlays.length) {
        $("body").removeClass("has-underlay");
      }
    }
  }
}

export default new App();
