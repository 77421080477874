import $ from "jquery";
import { URLS } from "../../scripts/config";
import App from "../../scripts/src/App";
import "../../scripts/components/quantity-spinner";
import Cart from "../../scripts/src/Cart";
import MiniCart from "./MiniCartClass";
import { debounce } from "lodash";

App.MiniCart = MiniCart;

const getURLVar = (key) => new URLSearchParams(location.search).get(key);

const getCartData = () => {
  $.post(
    URLS.cart_info,
    {
      type: $(".cart-modal").data("type"),
    },
    (res) => {
      $("[data-cart-total]").text(res.text_items);
      $(".cart-modal__products").html(res.products);
      $(".cart-modal__vouchers").html(res.vouchers);
      $(".cart-modal__totals").html(res.totals);
      $(".cart-modal__checkout").toggleClass("d-none", !Number(res.text_items));
    },
  );
};

(async () => {
  $(document).on(
    "cart.add.success,cart.edit.success,cart.remove.success",
    (e, { response: { success }, data }) => {
      getCartData();

      if (e.namespace !== "add.success") {
        if (getURLVar("route") === "checkout/cart" || getURLVar("route") === "checkout/checkout") {
          location = "index.php?route=checkout/cart";
        }

        if (success) {
          App.MessageCollection.clearContext("cart");
          App.MessageCollection.add(`product-${data.key}`, success, "success", "cart");
        }
      }
    },
  );

  $(document).on("cart.edit.error,cart.remove.error", (e) => {
    if (e.namespace !== "add.error") {
      App.MessageCollection.clearContext("cart");
      App.MessageCollection.add(
        "cart",
        "There was an error while updating your cart. Your cart didn't update!",
        "danger",
        "cart",
      );

      // Get cart data again to rollback quantity
      if (e.namespace === "edit.error") {
        getCartData();
      }
    }
  });

  $(".js-cart-modal-toggle").click(() => {
    MiniCart.toggle();
  });

  $(".js-cart-modal-close").click(() => {
    MiniCart.hide();
  });

  $(document).on("click", (e) => {
    if (
      !$(e.target).closest(".js-cart-modal").length &&
      !$(e.target).closest(".js-cart-modal-toggle").length
    ) {
      MiniCart.hide();
    }
  });

  $(document).on(
    "change",
    ".cart-modal input[name^='input-quantity-']",
    debounce(async function () {
      const $input = $(this);
      const key = $input.attr("name").replace("input-quantity-", "");
      const quantity = $input.val();
      try {
        await Cart.update(key, quantity);
      } catch {}
    }, 500),
  );

  matchMedia("(min-width: 768px)").addEventListener("change", () => {
    $(".cart-modal").addClass("no-transition");
    requestAnimationFrame(() => {
      $(".cart-modal").removeClass("no-transition");
    });
  });
})();
