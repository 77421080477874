/**
 * Class represents a custom CSS property
 * @class
 * @example
 * const property = new CustomProperty('name', 10);
 */
class CustomProperty {
  #ctx;
  #name;
  #_value;

  /**
   * @param {string} name - The CSS property name
   * @param {string|number} value - The CSS property value
   * @param {HTMLElement} [context=document.documentElement] - The DOM Element style with apply to
   */
  constructor(name, value, context = document.documentElement) {
    this.#ctx = context;
    this.#name = name;
    this.value = value;
  }

  /**
   * The property value
   * @type {any}
   * @example
   * const property = new CustomProperty('name', 10);
   * // Also updates the DOM style
   * property.value = 100;
   */
  static get value() {}

  set value(value) {
    this.#_value = value;
    this.#ctx.style.setProperty(`--${this.#name}`, this.#_value);
  }

  get value() {
    return this.#_value;
  }

  /**
   * Transforms this to string
   * @returns {string} The string representation of this.
   * @example
   * const property = new CustomProperty('name', 10);
   * console.log(`${property}`);
   */
  toString() {
    return this.value.toString();
  }
  /**
   * Sets value to null and clears DOM style
   */
  destroy() {
    this.value = null;
  }
}
export default CustomProperty;
