import $ from "jquery";

$(document).on("click", "[data-spinner-increase]", function () {
  const $controller = $(this).closest("[data-spinner]");
  const max = parseInt($controller.attr("data-spinner-max")) || 999;
  const step = parseInt($controller.attr("data-spinner-step")) || 1;
  const $input = $controller.find("[data-spinner-input]");
  $input.val(Math.min(parseInt($input.val()) + step, max)).change();
});

$(document).on("click", "[data-spinner-decrease]", function () {
  const $controller = $(this).closest("[data-spinner]");
  const min = parseInt($controller.attr("data-spinner-min")) || -999;
  const step = parseInt($controller.attr("data-spinner-step")) || 1;
  const $input = $controller.find("[data-spinner-input]");
  $input.val(Math.max(parseInt($input.val()) - step, min)).change();
});
