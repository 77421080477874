import Message from "./Message";

export default class MessageCollection {
  /** @type Element */
  #container;
  /** @type MutationObserver */
  #observer;
  /** @type {Message} */
  #messageFactory;
  /** @type {Object.<string, {id: string, message: Message}[]>} */
  #messages = {};

  /**
   * @param {Message} messageFactory
   * @param {Element} container
   */
  constructor(messageFactory, container) {
    try {
      if (!messageFactory || Object.getPrototypeOf(messageFactory) !== Message) {
        console.warn("Message factory not provided or not an Prototype of Message");
      }

      if (!container || !(container instanceof Element)) {
        console.warn("Container must be an instance of Element");
      }

      this.#messageFactory = messageFactory;
      this.#container = container;

      this.#observer = new MutationObserver((mutationList) => {
        for (const mutation of mutationList) {
          if (mutation.removedNodes.length) {
            mutation.removedNodes.forEach((node) => {
              Object.entries(this.#messages).forEach(([context, messages]) => {
                messages.forEach((msg) => {
                  if (msg.message.el === node) {
                    this.remove(msg.id, context);
                  }
                });
              });
            });
          }
        }
      });
      this.#observer.observe(this.#container, { childList: true });
    } catch (e) {
      console.warn(e.message);
    }
  }

  /**
   * @param id - The id of the message
   * @param {string} text - The message to display
   * @param {string} type - The type of message
   * @param {string} [context=default] - The context of the message
   * @param {boolean} [dismissible=true] - Whether the message can be dismissed
   * @param {number} [timeout=0] - MS to auto remove the message
   */
  add(id, text, type, context = "default", dismissible = true, timeout = 0) {
    this.#messages[context] = this.#messages[context] || [];
    const message = new this.#messageFactory(text, type, dismissible, timeout);

    this.#messages[context].push({ id, message });
    try {
      this.#container.prepend(message.el);
    } catch (e) {
      console.warn("Could not add message to container. Container not found.");
    }

    return this;
  }

  remove(id, context = "default") {
    if (this.#messages[context]) {
      const message = this.#messages[context].find((msg) => msg.id === id);
      message?.message.el?.remove();
      this.#messages[context] = this.#messages[context].filter((msg) => msg.id !== id);
    }
    return this;
  }

  clear() {
    Object.keys(this.#messages).forEach((context) => this.clearContext(context));
    return this;
  }

  getContext(context = "default") {
    return this.#messages[context] || [];
  }

  clearContext(context = "default") {
    if (this.#messages[context]) {
      this.#messages[context].forEach((msg) => {
        this.remove(msg.id, context);
      });
    }
    return this;
  }

  destroy() {
    this.#observer.disconnect();
    this.clear();
  }
}
