import Message from "./Message";

export default class BootstrapAlertMessage extends Message {
  _element;

  constructor(message, type, dismissible = true, timeout = 0) {
    super(message, type, dismissible, timeout);

    const element = document.createElement("div");
    element.classList.add("alert", `alert-${type}`, dismissible && "alert-dismissible");
    element.innerHTML = message;

    if (dismissible) {
      element.innerHTML += `<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>`;
    }

    this._element = element;
  }
}
